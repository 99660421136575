import React from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import htmlParser from 'html-react-parser';

import "bootstrap";
import "react-summernote/dist/react-summernote.css";
import "../../../../vendor/summernote/table-plugin/summernote-ext-table.css";

import ReactSummernote from "react-summernote";
import "../../../../vendor/summernote/table-plugin/summernote-ext-table.js";

import "./TableEditor.css";

import TablePainter from "../TablePainter";
import { getHTMLContent } from "../../../utils/helpers/html-content";

function TableEditor(props) {
  const {
    content,
    setContent,
  } = props;

  const onChange = (content) => {
    const reactElement = htmlParser(content);

    const tables = getHTMLContent(reactElement, "table", "target");
    const tableContents = getHTMLContent(tables[0], "tr", "children");

    if (tableContents.length < 1) {
      setContent({});
      return;
    }

    const headerElements = Array.isArray(tableContents[0]) ? tableContents[0] : [tableContents[0]];
    const headers = headerElements.map(element => renderToStaticMarkup(element));

    tableContents.shift();
    const rows = tableContents.map(tableRow =>
      Array.isArray(tableRow) ? tableRow.map(cell => renderToStaticMarkup(cell)) : [renderToStaticMarkup(tableRow)]
    );

    setContent({ headers, rows });
  }

  return (
    <ReactSummernote
      options={{
        height: 350,
        dialogsInBody: true,
        toolbar: [
          ["style", []],
          ["font", ["bold", "italic", "underline"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["view", ["codeview"]]
        ],
        // table plugin
        popover: {
          table: [
            ['style', ['jAddDeleteRowCol']],
            ['merge', ['jMerge']],
            // ['info', ['jTableInfo']],
            // ['delete', ['jWidthHeightReset', 'deleteTable']],
          ]
        },
        jTable : {
          /**
           * drag || dialog
           */
          mergeMode: 'drag'
        }
      }}
      onChange={onChange}>
      <TablePainter content={content} />
    </ReactSummernote>
  );
}

export default TableEditor;
