import React, { useState, useRef } from "react";

import "bootstrap";

import { range, sliceIntoChunks } from "../../../../utils/helpers/array";

function PrettyList(props) {
  const {
    baseContents,
    subsectionId,
    subsectionName,
    productBaseSectionRefs,
    columnCount,
  } = props;

  return(
    <>
      {
        range(0, Math.ceil(baseContents.length / columnCount) - 1).map((index) => {
          return(
            <div className="row">
              <div className="form-group">
                {
                  sliceIntoChunks(baseContents, columnCount)[index].map((base) => {
                    const baseIndex = baseContents.indexOf(base);
                    const contentId = `${subsectionId}_base_contents_attributes_${baseIndex}`;
                    const contentName = `${subsectionName}[base_contents_attributes][${baseIndex}]`;

                    const [title, setTitle] = useState(base.title);
                    const [content, setContent] = useState(base.content);
                    productBaseSectionRefs.id.push(useRef());
                    productBaseSectionRefs.title.push(useRef());
                    productBaseSectionRefs.content.push(useRef());

                    return(
                      <div className="field col-sm-6">
                        <input
                          id={`${contentId}_id`}
                          name={`${contentName}[id]`}
                          type="hidden"
                          ref={productBaseSectionRefs.id[baseIndex]}
                          value={base.id}
                        />

                        <label className="form-label" htmlFor={`${contentName}[title]`}>
                          Title
                        </label>
                        <input
                          id={`${contentId}_title`}
                          className="form-control"
                          name={`${contentName}[title]`}
                          type="text"
                          ref={productBaseSectionRefs.title[baseIndex]}
                          value={title}
                          onChange={ (event) => setTitle(event.target.value) }/>

                        <label className="form-label" htmlFor={`${contentName}[content]`}>
                          Content
                        </label>
                        <textarea
                          id={`${contentId}_content`}
                          className="form-control"
                          name={`${contentName}[content]`}
                          ref={productBaseSectionRefs.content[baseIndex]}
                          value={content}
                          onChange={ (event) => setContent(event.target.value) }/>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          );
        })
      }
    </>
  );
}

export default PrettyList;
