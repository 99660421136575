import htmlParser from "html-react-parser";
import { getHTMLContent } from "../helpers/html-content";
import { LINE_HEIGHT, BLUE_COLOR, BLACK_COLOR, DEFAULT_PAGE_MARGIN } from "./constants";

const POINT_PER_INCH = 72;

const PARAGRAPH_PADDING = 0.25;
const LIST_ELEMENT_PADDING = 0.1;

const SUPPORTED_ROOT_TAGS = ['p', 'ol', 'li'];

const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);
const checkByListElement = (str) => str.match(/(^\d+)(\s*)([.|])/);
export const contains$ = (str) => /([$])([^\s]*)/.test(str);

export const pointsToInches = (points, k = LINE_HEIGHT) => (points * k) / POINT_PER_INCH;

function calculateOffset(doc, content, options) {
  const linePoints = doc
    .setFont(options.font, options.fontWeight)
    .getStringUnitWidth(content) * options.fontSize;

  return pointsToInches(linePoints, 1);
}

function calculatePagePosition(doc, render, components, x, y, options) {
  const [, proposalHeight] = render(doc, components, x, y, { ...options, calculate: true });
  if ((doc.internal.pageSize.getHeight() - DEFAULT_PAGE_MARGIN) < (y + proposalHeight)) {
    doc.addPage();

    return 0.8 + pointsToInches(options.fontSize);
  }
  return y;
}

function enrichComponents(components) {
  // enrich elements for custom styling
  let enrichComponents = [];
  for (let index = 0; index < components.length; index++) {
    if (typeof components[index] !== 'string' && components[index].type === 'b') {
      const content = getHTMLContent(components[index], "b", "children")[0];
      if (checkByListElement(content)) {
        const listNumber = checkByListElement(content)[0];
        const listValue = content.split(listNumber)[1];
        const enrichListComponents = htmlParser(`<b>${listNumber}</b><b>${listValue}</b>`);
        enrichComponents = [...enrichComponents,  ...enrichListComponents];
        continue;
      }
    }

    enrichComponents.push(components[index]);
  }

  return enrichComponents;
}

export function addText(doc, content, x, y, options) {
  if (options.widthOffset) {
    const pieceOfLine = doc
      .setFont(options.font, options.fontWeight)
      .setFontSize(options.fontSize)
      .splitTextToSize(content, options.maxLineWidth - options.widthOffset)[0];

    if (!options.calculate) {
      doc.text(
        pieceOfLine,
        x + options.widthOffset, y,
        { lineHeightFactor: options.customLineHeight || LINE_HEIGHT },
      );
    }

    content = content.split(pieceOfLine)[1];
    if (!content) {
      return [options.widthOffset + calculateOffset(doc, pieceOfLine, options), 0];
    }

    y += pointsToInches(options.fontSize);
  }

  const textLines = doc
    .setFont(options.font, options.fontWeight)
    .setFontSize(options.fontSize)
    .splitTextToSize(content, options.maxLineWidth);

  if (!options.calculate) {
    doc.text(textLines, x, y, { lineHeightFactor: options.customLineHeight || LINE_HEIGHT });
  }

  const lastLine = textLines[textLines.length - 1];
  const widthOffset = calculateOffset(doc, lastLine, options);
  const blockHeight = textLines.length * pointsToInches(options.fontSize);
  return [widthOffset, blockHeight];
}

export function renderHTMLTextComponents(doc, components, x, y, options) {
  let height = 0;
  let widthOffset, blockHeight;
  let localOptions = {};

  // enrich element for custom styling
  components = enrichComponents(
    components.filter(c => c)).filter(c => c.type || !c.includes('\n'));

  components.forEach((component) => {
    doc.setTextColor(BLACK_COLOR);
    localOptions = { ...localOptions, ...options };

    if (typeof component !== 'string') {
      if (component.type === 'b') {
        component = getHTMLContent(component, "b", "children")[0];
        localOptions = { ...localOptions, fontWeight: 'bold' };
        if (checkByListElement(component)) {
          doc.setTextColor(BLUE_COLOR);
        }
      } else {
        return;
      }
    }

    [widthOffset, blockHeight] = addText(doc, component, x, y, localOptions);
    localOptions = { ...localOptions, widthOffset };
    height += blockHeight;
  });

  return [localOptions.widthOffset, height];
}

function renderHTMLElements(doc, elements, x, y, options) {
  elements.forEach((element, index) => {
    let blockHeight;

    if (SUPPORTED_ROOT_TAGS.includes(element.type)) {
      let components = getHTMLContent(element, element.type, "children")[0];

      if (element.type === 'ol') {
        const list = components.filter(c => c.type);
        y = renderHTMLElements(doc, list, x, y, options);
        y += PARAGRAPH_PADDING;
        return;
      } else if (element.type === 'li') {
        const elementNumber = htmlParser(`<b>${index + 1}. </b>`);

        if (Array.isArray(components)) {
          components = [elementNumber, ...components];
        } else {
          components = [elementNumber, components];
        }
      }

      if (!Array.isArray(components) && typeof components === 'string') {
        y = calculatePagePosition(doc, addText, components, x, y, options);
        [, blockHeight] = addText(doc, components, x, y, options);
      } else {
        components = Array.isArray(components) ? components : [components];
        y = calculatePagePosition(doc, renderHTMLTextComponents, components, x, y, options);
        [, blockHeight] = renderHTMLTextComponents(doc, components, x, y, options);
      }

      y += blockHeight;
    }

    if (elements.length - 1 !== index) {
      y += element.type === 'p' ? PARAGRAPH_PADDING : LIST_ELEMENT_PADDING;
    }
  });

  return y;
}

export function addHTMLText(doc, content, x, y, options) {
  if (!containsHTML(content)) {
    y = calculatePagePosition(doc, addText, content, x, y, options);
    const [, blockHeight] = addText(doc, content, x, y, options);
    y += blockHeight;
  } else {
    const elements = htmlParser(content);
    y = Array.isArray(elements)
      ? renderHTMLElements(doc, elements.filter(e => e.type || !e.includes('\n')), x, y, options)
      : renderHTMLElements(doc, [elements], x, y, options);
  }

  return y;
}
