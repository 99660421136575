import React, { useState, useRef } from "react";

import "bootstrap";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";

import htmlParser from 'html-react-parser';

import GanttEditor from '../UI/GanttEditor/GanttEditor';
import TableEditor from "../UI/TableEditor/TableEditor";
import ProductSubsectionPrettyList from "../UI/Product/Subsection/PrettyList";
import ProductSubsectionContactInfo from "../UI/Product/Subsection/ContactInfo";
import ProductSubsectionContactAddresses from "../UI/Product/Subsection/ContactAddresses";

import { getFormData } from "../../utils/product-section-content";
import { cleanHTML } from "../../utils/helpers/html-formatting";
import { updateProductSectionHandler } from "../../api/request-builder";

export default function EditProductSection(props) {
  const {
    product_section,
  } = props;

  const [productSectionName, setProductSectionName] = useState(product_section.name);
  const productSubsectionNames = [];
  const setProductSubsectionNames = [];

  const productSectionContentKeys = [];
  const productSectionContents = [];
  const setProductSectionContents = [];

  const productSectionNameInputRef = useRef();
  const productSubsectionIdInputRefs = [];
  const productSubsectionNameInputRefs = [];

  const productSectionContentIdInputRefs = [];
  const productSectionContentTableStyleInputRefs = [];

  const productBaseSectionRefs = {
    id: [],
    title: [],
    content: [],
  };
  const productContactInfoRefs = {
    id: undefined,
    phone: undefined,
    email: undefined,
  };
  const productContactAddressRefs = {
    relation_id: [],
    id: [],
    country: [],
    city: [],
    street: [],
    post_code: [],
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateProductSectionHandler(
      product_section,
      productSectionNameInputRef,
      productSubsectionIdInputRefs,
      productSubsectionNameInputRefs,
      productSectionContentIdInputRefs, productSectionContentTableStyleInputRefs,
      productSectionContentKeys, productSectionContents,
      productBaseSectionRefs,
      productContactInfoRefs,
      productContactAddressRefs,
    );
  };

  const sectionName = 'product_section';
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group">
            <div className="field col-sm-6">
              <label className="form-label" htmlFor="product_section[name]">
                Section name
              </label>
              <input id={`${sectionName}_name`}
                     className="form-control"
                     name={`${sectionName}[name]`}
                     type="text"
                     ref={productSectionNameInputRef}
                     value={productSectionName}
                     onChange={ (event) => setProductSectionName(event.target.value) }/>
            </div>
          </div>
        </div>

        {
          product_section
            .product_subsections
            .sort((a, b) => a.sequence - b.sequence)
            .map((subsection, subsectionIndex) => {

            const productSubsectionIdInputRef = useRef();
            productSubsectionIdInputRefs.push(productSubsectionIdInputRef);
            const productSubsectionNameInputRef = useRef();
            productSubsectionNameInputRefs.push(productSubsectionNameInputRef);

            const [productSubsectionName, setProductSubsectionName] = useState(subsection.name);
            productSubsectionNames.push(productSubsectionName);
            setProductSubsectionNames.push(setProductSubsectionName);

            const subsectionId = `${sectionName}_product_subsections_attributes_${subsectionIndex}`;
            const subsectionName = `${sectionName}[product_subsections_attributes][${subsectionIndex}]`;
            return (
              <>
                <div className="row">
                  <div className="field col-sm-6">
                    <label
                      className="form-label"
                      htmlFor={`${subsectionName}[name]`}>
                      Subsection name
                    </label>
                    <input
                      type="hidden"
                      id={`${subsectionId}_id`}
                      name={`${subsectionName}[id]`}
                      ref={productSubsectionIdInputRefs[subsectionIndex]}
                      value={subsection.id}/>
                    <input
                      id={`${subsectionId}_name`}
                      className="form-control"
                      name={`${subsectionName}[name]`}
                      type="text"
                      ref={productSubsectionNameInputRefs[subsectionIndex]}
                      value={productSubsectionNames[subsectionIndex]}
                      onChange={ (event) => setProductSubsectionNames[subsectionIndex](event.target.value) }/>
                  </div>
                </div>

                {
                  (subsection.content_type === 'usual' || subsection.content_type === 'medium') &&
                    <>
                      {
                        subsection
                          .product_section_contents
                          .sort((a, b) => a.sequence - b.sequence)
                          .map((content, contentIndex) => {
                            const productSectionContentIdInputRef = useRef();
                            const productSectionContentTableStyleInputRef = useRef();

                            const contentId = `${subsectionId}_product_section_contents_attributes_${contentIndex}`;
                            const contentName = `${subsectionName}[product_section_contents_attributes][${contentIndex}]`;

                            const [
                              productSectionContentKey,
                              productSectionContentDisclaimer,
                              productSectionContent,
                              setProductSectionContent,
                            ] = getFormData(content, contentName);

                            if (contentIndex === 0) {
                              productSectionContentIdInputRefs.push([productSectionContentIdInputRef]);
                              productSectionContentTableStyleInputRefs.push([productSectionContentTableStyleInputRef]);

                              productSectionContentKeys.push([productSectionContentKey]);
                              productSectionContents.push([productSectionContent]);
                              setProductSectionContents.push([setProductSectionContent]);
                            } else {
                              productSectionContentIdInputRefs[subsectionIndex].push(productSectionContentIdInputRef);
                              productSectionContentTableStyleInputRefs[subsectionIndex].push(productSectionContentTableStyleInputRef);

                              productSectionContentKeys[subsectionIndex].push(productSectionContentKey);
                              productSectionContents[subsectionIndex].push(productSectionContent);
                              setProductSectionContents[subsectionIndex].push(setProductSectionContent);
                            }

                            return (
                              <>
                                <input
                                  type="hidden"
                                  id={`${contentId}_id`}
                                  name={`${contentName}[id]`}
                                  ref={productSectionContentIdInputRefs[subsectionIndex][contentIndex]}
                                  value={content.id}
                                />

                                <div className="row">
                                  <div className="field col-sm-12">
                                    <div className="span12">
                                      <i>{productSectionContentDisclaimer}</i>
                                    </div>
                                  </div>
                                </div>

                                {
                                  (content.content_type === "gantt_chart") &&
                                  <GanttEditor
                                    content={productSectionContents[subsectionIndex][contentIndex]}
                                    setContent={setProductSectionContents[subsectionIndex][contentIndex]}/>
                                }
                                {
                                  content.content_type === "html_table" &&
                                  <>
                                    <input
                                      type="hidden"
                                      id={`${contentId}_table_style`}
                                      name={`${contentName}[table_style]`}
                                      ref={productSectionContentTableStyleInputRefs[subsectionIndex][contentIndex]}
                                      value={content.table_style}/>
                                    <TableEditor
                                      content={productSectionContents[subsectionIndex][contentIndex]}
                                      setContent={setProductSectionContents[subsectionIndex][contentIndex]}/>
                                  </>
                                }
                                {
                                  (content.content_type === "text" || content.content_type === "pretty_list") &&
                                  <ReactSummernote
                                    options={{
                                      height: 350,
                                      dialogsInBody: true,
                                      toolbar: [
                                        ["style", []],
                                        ["font", ["bold", "italic", "underline"]],
                                        ["para", ["ul", "ol"]],
                                        ["insert", []],
                                        ["view", ["codeview"]]
                                      ]
                                    }}
                                    onChange={ (content) => setProductSectionContents[subsectionIndex][contentIndex](cleanHTML(content)) }>
                                    {htmlParser(productSectionContents[subsectionIndex][contentIndex])}
                                  </ReactSummernote>
                                }
                              </>
                            );
                          })
                      }
                    </>
                }

                {
                  (subsection.content_type === 'pretty_list') &&
                    <div className="well">
                      <ProductSubsectionPrettyList
                        baseContents={subsection.base_contents.sort((a, b) => a.sequence - b.sequence)}
                        subsectionId={subsectionId}
                        subsectionName={subsectionName}
                        productBaseSectionRefs={productBaseSectionRefs}
                        columnCount={2}
                      />
                    </div>
                }

                {
                  (subsection.content_type === 'contact_info') &&
                    <div className="well">
                      <ProductSubsectionContactInfo
                        contactInfo={subsection.contact_info}
                        subsectionId={subsectionId}
                        subsectionName={subsectionName}
                        productContactInfoRefs={productContactInfoRefs}
                        fields={[
                          { name: 'Phone', property: 'phone' },
                          { name: 'Email', property: 'email' },
                        ]}
                      />
                      <ProductSubsectionContactAddresses
                        contactAddresses={subsection.addresses.sort((a, b) => a.id - b.id)}
                        subsectionId={subsectionId}
                        subsectionName={subsectionName}
                        productContactAddressRefs={productContactAddressRefs}
                        fields={[
                          { name: 'Country', property: 'country' },
                          { name: 'City', property: 'city' },
                          { name: 'Street', property: 'street' },
                          { name: 'Post code', property: 'post_code' },
                        ]}
                        columnCount={4}
                      />
                    </div>
                }
              </>
            );
          })
        }

        {/* TODO: add block if submitted */}
        <div className="actions">
          <button className="btn btn-primary" name="button" type="submit">Save</button>
        </div>
      </form>
    </>
  );
}
