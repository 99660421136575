import { updateProductDisclaimerSection, updateProductSection, updateWalletQuote } from "./backend-client";

export function updateProductDisclaimerSectionHandler(disclaimer) {
  const formPayload = new FormData();
  formPayload.append("product_disclaimer_section[content]", disclaimer);

  (async () => {
    const response = await updateProductDisclaimerSection(formPayload);
    window.location = response.url;
  })();
}

export function updateProductSectionHandler(
  product_section,
  productSectionNameInputRef,
  productSubsectionIdInputRefs,
  productSubsectionNameInputRefs,
  productSectionContentIdInputRefs, productSectionContentTableStyleInputRefs,
  productSectionContentKeys, productSectionContents,
  productBaseSectionRefs,
  productContactInfoRefs,
  productContactAddressRefs,
) {
  // target field
  const formPayload = new FormData();
  formPayload.append(productSectionNameInputRef.current.name, String(productSectionNameInputRef.current.value));

  product_section.product_subsections.sort((a, b) => a.sequence - b.sequence).forEach((subsection, subsectionIndex) => {
    formPayload.append(
      productSubsectionIdInputRefs[subsectionIndex].current.name,
      String(productSubsectionIdInputRefs[subsectionIndex].current.value)
    );
    formPayload.append(
      productSubsectionNameInputRefs[subsectionIndex].current.name,
      String(productSubsectionNameInputRefs[subsectionIndex].current.value)
    );

    // contents block
    subsection.product_section_contents.sort((a, b) => a.sequence - b.sequence).forEach((_content, contentIndex) => {
      formPayload.append(
        productSectionContentIdInputRefs[subsectionIndex][contentIndex].current.name,
        String(productSectionContentIdInputRefs[subsectionIndex][contentIndex].current.value)
      );

      // main content area
      let content;
      if (_content.content_type === "gantt_chart" || _content.content_type === "html_table") {
        // table style
        if (_content.content_type === "html_table") {
          formPayload.append(
            productSectionContentTableStyleInputRefs[subsectionIndex][contentIndex].current.name,
            String(productSectionContentTableStyleInputRefs[subsectionIndex][contentIndex].current.value)
          );
        }
        // json content
        content = JSON.stringify(productSectionContents[subsectionIndex][contentIndex] || {});
      } else {
        // string content
        content = productSectionContents[subsectionIndex][contentIndex] || "";
      }
      formPayload.append(productSectionContentKeys[subsectionIndex][contentIndex], content);
    });

    // pretty list
    subsection.base_contents.sort((a, b) => a.sequence - b.sequence).forEach((_, baseIndex) => {
      formPayload.append(
        productBaseSectionRefs.id[baseIndex].current.name,
        String(productBaseSectionRefs.id[baseIndex].current.value),
      );
      formPayload.append(
        productBaseSectionRefs.title[baseIndex].current.name,
        String(productBaseSectionRefs.title[baseIndex].current.value),
      );
      formPayload.append(
        productBaseSectionRefs.content[baseIndex].current.name,
        String(productBaseSectionRefs.content[baseIndex].current.value),
      );
    });

    // contact info
    if (productContactInfoRefs.id && productContactInfoRefs.phone && productContactInfoRefs.email) {
      formPayload.append(
        productContactInfoRefs.id.current.name,
        String(productContactInfoRefs.id.current.value),
      );
      formPayload.append(
        productContactInfoRefs.phone.current.name,
        String(productContactInfoRefs.phone.current.value),
      );
      formPayload.append(
        productContactInfoRefs.email.current.name,
        String(productContactInfoRefs.email.current.value),
      );
    }

    // contact addresses
    subsection.addresses.sort((a, b) => a.id - b.id).forEach((_, addressIndex) => {
      formPayload.append(
        productContactAddressRefs.relation_id[addressIndex].current.name,
        String(productContactAddressRefs.relation_id[addressIndex].current.value),
      );
      formPayload.append(
        productContactAddressRefs.id[addressIndex].current.name,
        String(productContactAddressRefs.id[addressIndex].current.value),
      );
      formPayload.append(
        productContactAddressRefs.country[addressIndex].current.name,
        String(productContactAddressRefs.country[addressIndex].current.value),
      );
      formPayload.append(
        productContactAddressRefs.city[addressIndex].current.name,
        String(productContactAddressRefs.city[addressIndex].current.value),
      );
      formPayload.append(
        productContactAddressRefs.street[addressIndex].current.name,
        String(productContactAddressRefs.street[addressIndex].current.value),
      );
      formPayload.append(
        productContactAddressRefs.post_code[addressIndex].current.name,
        String(productContactAddressRefs.post_code[addressIndex].current.value),
      );
    });
  });

  (async () => {
    const response = await updateProductSection(product_section.id, formPayload);
    window.location = response.url;
  })();
}

export function updateWalletQuoteHandler(
  quote, dependent_product_sections,
  walletQuoteNameInputRef, walletQuoteName,
  walletQuoteValidDateParameterName, walletQuoteValidDate,
  walletQuoteDependentProductSectionIdInputRefs, walletQuoteDependentProductSectionContentTypeInputRefs, walletQuoteDependentProductSectionProductSectionIdRefs,
  walletQuoteDependentProductSubsectionIdRefs, walletQuoteDependentProductSubsectionProductSubsectionIdRefs,
  walletQuoteDependentProductSectionContentIdInputRefs,
  walletQuoteDependentProductSectionContentContentTypeInputRefs,
  walletQuoteDependentProductSectionContentContentIdInputRefs,
  walletQuoteDependentProductSectionContentTableStyleRefs,
  walletQuoteDependentProductSectionContentKeys, walletQuoteDependentProductSectionContents,
  productBaseSectionRefs,
  productContactInfoRefs,
) {
  // target field
  const formPayload = new FormData();
  formPayload.append(walletQuoteNameInputRef.current.name, String(walletQuoteName));
  formPayload.append(walletQuoteValidDateParameterName, String(walletQuoteValidDate));

  for (let section_index = 0; section_index < dependent_product_sections.length; section_index++) {
    formPayload.append(
      walletQuoteDependentProductSectionIdInputRefs[section_index].current.name,
      String(walletQuoteDependentProductSectionIdInputRefs[section_index].current.value)
    );
    formPayload.append(
      walletQuoteDependentProductSectionContentTypeInputRefs[section_index].current.name,
      String(walletQuoteDependentProductSectionContentTypeInputRefs[section_index].current.value)
    );
    formPayload.append(
      walletQuoteDependentProductSectionProductSectionIdRefs[section_index].current.name,
      String(walletQuoteDependentProductSectionProductSectionIdRefs[section_index].current.value)
    );

    const dependent_product_subsections = dependent_product_sections[section_index].dependent_product_subsections;
    for (let subsection_index = 0; subsection_index < dependent_product_subsections.length; subsection_index++) {
      formPayload.append(
        walletQuoteDependentProductSubsectionIdRefs[section_index][subsection_index].current.name,
        String(walletQuoteDependentProductSubsectionIdRefs[section_index][subsection_index].current.value)
      );
      formPayload.append(
        walletQuoteDependentProductSubsectionProductSubsectionIdRefs[section_index][subsection_index].current.name,
        String(walletQuoteDependentProductSubsectionProductSubsectionIdRefs[section_index][subsection_index].current.value)
      );

      const dependent_product_section_contents = dependent_product_subsections[subsection_index].dependent_product_section_contents;
      for (let content_index = 0; content_index < dependent_product_section_contents.length; content_index++) {
        // hidden content fields
        formPayload.append(
          walletQuoteDependentProductSectionContentIdInputRefs[section_index][subsection_index][content_index].current.name,
          String(walletQuoteDependentProductSectionContentIdInputRefs[section_index][subsection_index][content_index].current.value)
        );
        formPayload.append(
          walletQuoteDependentProductSectionContentContentTypeInputRefs[section_index][subsection_index][content_index].current.name,
          String(walletQuoteDependentProductSectionContentContentTypeInputRefs[section_index][subsection_index][content_index].current.value)
        );
        formPayload.append(
          walletQuoteDependentProductSectionContentContentIdInputRefs[section_index][subsection_index][content_index].current.name,
          String(walletQuoteDependentProductSectionContentContentIdInputRefs[section_index][subsection_index][content_index].current.value)
        );

        // main content area
        let content;
        if (dependent_product_section_contents[content_index].content_type === "gantt_chart" ||
          dependent_product_section_contents[content_index].content_type === "html_table") {
          if (dependent_product_section_contents[content_index].content_type === "html_table") {
            // add table styles
            formPayload.append(
              walletQuoteDependentProductSectionContentTableStyleRefs[section_index][subsection_index][content_index].current.name,
              String(walletQuoteDependentProductSectionContentTableStyleRefs[section_index][subsection_index][content_index].current.value)
            );
          }
          // add json content
          content = JSON.stringify(walletQuoteDependentProductSectionContents[section_index][subsection_index][content_index] || {});
        } else {
          // add string content
          content = walletQuoteDependentProductSectionContents[section_index][subsection_index][content_index] || "";
        }
        formPayload.append(walletQuoteDependentProductSectionContentKeys[section_index][subsection_index][content_index], content);
      }

      const base_contents = dependent_product_subsections[subsection_index].base_contents;
      // pretty list
      base_contents.sort((a, b) => a.sequence - b.sequence).forEach((_, baseIndex) => {
        formPayload.append(
          productBaseSectionRefs.id[baseIndex].current.name,
          String(productBaseSectionRefs.id[baseIndex].current.value),
        );
        formPayload.append(
          productBaseSectionRefs.title[baseIndex].current.name,
          String(productBaseSectionRefs.title[baseIndex].current.value),
        );
        formPayload.append(
          productBaseSectionRefs.content[baseIndex].current.name,
          String(productBaseSectionRefs.content[baseIndex].current.value),
        );
      });

      // contact info
      if (productContactInfoRefs.id && productContactInfoRefs.phone && productContactInfoRefs.email) {
        formPayload.append(
          productContactInfoRefs.id.current.name,
          String(productContactInfoRefs.id.current.value),
        );
        formPayload.append(
          productContactInfoRefs.phone.current.name,
          String(productContactInfoRefs.phone.current.value),
        );
        formPayload.append(
          productContactInfoRefs.email.current.name,
          String(productContactInfoRefs.email.current.value),
        );
      }
    }
  }

  (async () => {
    const response = await updateWalletQuote(quote.id, formPayload);
    window.location = response.url;
  })();
}
