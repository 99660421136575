import React from "react";

import "./GanttToolbar.css";

function GanttToolbar(props) {
  const {
    zoom,
    zoomOptions,
    onZoomChange,
  } = props;

  const onChange = (event) => {
    if (onZoomChange) {
      onZoomChange(event.target.value);
    }
  };

  return (
    <div className="zoom-bar">
      <div className="tool-bar">
        <b>Zoom: </b>
        {
          zoomOptions.map((value) => {
            const isActive = zoom === value;
            return (
              <label key={ value } className={ `radio-label ${isActive ? 'radio-label-active': ''}` }>
                <input type='radio'
                       checked={isActive}
                       onChange={onChange}
                       value={value}/>
                {value}
              </label>
            );
          })
        }
      </div>
    </div>
  );
}

export default GanttToolbar;
