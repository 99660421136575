import htmlParser from 'html-react-parser';

export function getHTMLContent(reactNodes, elementType, extractType, resultContent = []) {
  if (Array.isArray(reactNodes)) {
    reactNodes.forEach(reactNode => getHTMLContent(reactNode, elementType, extractType, resultContent));
  } else {
    const childNode = reactNodes?.props?.children;

    if (childNode === undefined) {
      return resultContent;
    }

    if (reactNodes.type === elementType) {
      if (extractType === "target") {
        resultContent.push(reactNodes);
      } else if (extractType === "children") {
        resultContent.push(childNode);
      } else {
        throw TypeError("Not supportable type");
      }
    } else {
      getHTMLContent(childNode, elementType, extractType, resultContent);
    }
  }
  return resultContent;
}

export function getTableContentStyles(htmlContent, parentElementType) {
  let element = htmlParser(htmlContent);

  const cleanProps = {
    colSpan: element.props.colSpan || 1,
  };

  const styles = {};
  if (element.props.style) {
    if (element.props.style.textAlign) {
      styles['halign'] = element.props.style.textAlign;
    }
  }

  while (true) {
    const childElements = getHTMLContent(element, parentElementType, "children")[0];

    if (Array.isArray(childElements)) {
      element = childElements.reduce((accumulator, currentValue) => {
        const content = getHTMLContent(currentValue, currentValue.type, "children");
        return accumulator + content + '\n';
      }, '');
    } else {
      element = childElements;
    }

    if (typeof element === 'string') {
      return [element, cleanProps, styles];
    } else if (!element) {
      return ['', cleanProps, styles];
    } else {
      parentElementType = element.type;

      if (parentElementType === 'b') {
        styles['fontStyle'] = 'bold';
      }
    }
  }
}
