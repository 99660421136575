import React, { useRef, useState } from "react";

import "bootstrap";

import { range, sliceIntoChunks } from "../../../../utils/helpers/array";

function ContactAddresses(props) {
  const {
    contactAddresses,
    subsectionId,
    subsectionName,
    productContactAddressRefs,
    fields,
    columnCount,
  } = props;

  return(
    range(0, Math.ceil(contactAddresses.length / columnCount) - 1).map((index) => {
      return(
        <div className="row">
          <div className="form-group">
            {
              sliceIntoChunks(contactAddresses, columnCount)[index].map((address) => {
                const addressIndex = contactAddresses.indexOf(address);
                const contactAddressId = `${subsectionId}_addresses_attributes_${addressIndex}_product_contact_address_attributes`;
                const contactAddressName = `${subsectionName}[addresses_attributes][${addressIndex}][product_contact_address_attributes]`;

                productContactAddressRefs.relation_id.push(useRef());
                productContactAddressRefs.id.push(useRef());
                const relationId = address.id;
                address = address.product_contact_address;

                return(
                  <div className={`field col-sm-${12 / columnCount}`}>
                    <input
                      id={`${subsectionId}_addresses_attributes_${addressIndex}_id`}
                      name={`${subsectionName}[addresses_attributes][${addressIndex}][id]`}
                      type="hidden"
                      ref={productContactAddressRefs.relation_id[addressIndex]}
                      value={relationId}
                    />
                    <input
                      id={`${contactAddressId}_id`}
                      name={`${contactAddressName}[id]`}
                      type="hidden"
                      ref={productContactAddressRefs.id[addressIndex]}
                      value={address.id}
                    />

                    {
                      fields.map(fieldObj => {
                        const [field, setField] = useState(address[fieldObj.property]);
                        productContactAddressRefs[fieldObj.property].push(useRef());

                        return(
                          <>
                            <label className="form-label" htmlFor={`${contactAddressName}[${fieldObj.property}]`}>
                              {fieldObj.name}
                            </label>
                            <input id={`${contactAddressId}_${fieldObj.property}`}
                                   className="form-control"
                                   name={`${contactAddressName}[${fieldObj.property}]`}
                                   type="text"
                                   ref={productContactAddressRefs[fieldObj.property][addressIndex]}
                                   value={field}
                                   onChange={ (event) => setField(event.target.value) }/>
                          </>
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      );
    })
  );
}

export default ContactAddresses;
