export function range(start, end) {
  return Array.from(Array(end - start + 1).keys()).map((_, index) => start + index);
}

export function sliceIntoChunks(array, chunkSize) {
  const result = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
}
