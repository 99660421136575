import { getTableContentStyles } from "../helpers/html-content";
import { contains$ } from "./text-formatting";

const TABLE_HEADER_COLORS = [
  [241, 242, 243],
  [230, 231, 233],
  [223, 224, 225],
];

const BLUE_COLOR = [235, 244, 255];
const YELLOW_COLOR = [255, 254, 243];
// const PINK_COLOR = [254, 245, 245];

const DEFAULT_TABLE_HEADER_ALIGN = 'center';
const DEFAULT_TABLE_ROWS_ALIGN = 'left';

export function getHeaders(htmlHeaders) {
  return [
    htmlHeaders.map((header, index) => {
      const [content, props, styles] = getTableContentStyles(
        header.replace(/(<br\/>)/g, ''),
        'td',
      );

      return {
        content,
        styles: {
          textColor: 0,
          halign: DEFAULT_TABLE_HEADER_ALIGN,
          fillColor: TABLE_HEADER_COLORS[index],
          ...styles,
        },
      };
    }),
  ];
}

export function getRows(htmlRows, style) {
  return htmlRows.map((row, index) => {
    return row.map((cell, index) => {
      const [content, props, styles] = getTableContentStyles(
        cell.replace(/(<br\/>)/g, ''),
        'td',
      );

      let fillColor;
      let textColor = 0;
      if (style === 'colorized' && props.colSpan > 1) {
        fillColor = YELLOW_COLOR;
        if (styles['halign'] === 'center') {
          fillColor = BLUE_COLOR;
          styles['fontStyle'] = 'medium';
        }
      } else if (style === 'strict' && contains$(content)) {
        textColor = [0, 55, 255];
      }

      return {
        content,
        ...props,
        styles: {
          textColor: textColor,
          halign: DEFAULT_TABLE_ROWS_ALIGN,
          fillColor: fillColor,
          ...styles,
        },
      };
    });
  });
}
