/* eslint no-undef: 0 */ // --> OFF

function getCSRF () {
  // get token from html document header
  return document.querySelector("meta[name='csrf-token']").getAttribute("content");
}

async function patchTemplate(url, data) {
  data.append('_method', 'patch');
  return await fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': getCSRF(),
    },
    body: data,
  });
}

export async function updateProductDisclaimerSection(data) {
  return await patchTemplate(`/product_sections/disclaimer`, data);
}

export async function updateProductSection(id, data) {
  return await patchTemplate(`/product_sections/${id}`, data);
}

export async function updateWalletQuote(id, data) {
  return await patchTemplate(`/wallet_quotes/${id}`, data);
}
