import { useState } from "react";

const GANTT_DISCLAIMER = "This section intended for build gantt charts";
const TABLE_DISCLAIMER = "This section can only contain a table," +
  " other content will be ignored when saving and pdf generating";
const TEXT_DISCLAIMER = "This section is for plain text";

export function getFormData(content, formPrefix) {
  let productSectionContentFormKey;
  let productSectionContentDisclaimer;
  let productSectionContentState;

  switch (content.content_type) {
    case 'gantt_chart':
      productSectionContentFormKey = `${formPrefix}[gantt_content]`;
      productSectionContentState = useState(content.gantt_content);
      productSectionContentDisclaimer = GANTT_DISCLAIMER;
      break;
    case 'html_table':
      productSectionContentFormKey = `${formPrefix}[table_content]`;
      productSectionContentState = useState(content.table_content);
      productSectionContentDisclaimer = TABLE_DISCLAIMER;
      break;
    case 'pretty_list':
    case 'text':
      productSectionContentFormKey = `${formPrefix}[text_content]`;
      productSectionContentState = useState(content.text_content);
      productSectionContentDisclaimer = TEXT_DISCLAIMER;
      break;
  }

  return [
    productSectionContentFormKey,
    productSectionContentDisclaimer,
    ...productSectionContentState,
  ];
}
