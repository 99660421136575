import { addHTMLText, addText, pointsToInches } from "./text-formatting";
import {
  LINE_HEIGHT,
  BLACK_COLOR,
  MONTSERRAT_PRODUCT_SECTION_TITLE,
  MONTSERRAT_PRODUCT_SUBSECTION_TITLE,
  GOTHAM_PRO_PRODUCT_SECTION_CONTENT,
  BLUE_COLOR,
  NORMAL_FIRST_PAGE_PADDING_LEFT,
  NORMAL_FIRST_PAGE_LOGO_PADDING_TOP,
  NORMAL_FIRST_PAGE_LINES_PADDING_CENTER,
  WHITE_COLOR,
  MONTSERRAT_PROJECT_TITLE,
  GOTHAM_PRO_PROJECT_DETAILS,
  GOTHAM_PRO_PROJECT_DATA,
} from "./constants";

import arrow from "../../../public/images/arrow.png";
import logoDark from "../../../public/images/logo-dark.png";
import linesDark from "../../../public/images/lines-dark.png";

import linkedin from "../../../public/images/linkedin.png";
import twitter from "../../../public/images/twitter.png";
import facebook from "../../../public/images/facebook.png";
import logo from "../../../public/images/logo.png";
import lines from "../../../public/images/lines.png";

// network constants
const NETWORK_ICONS = [linkedin, twitter, facebook];
const NETWORK_URLS = [
  'https://www.linkedin.com/company/velmie/',
  'https://twitter.com/VelmieHQ',
  'https://www.facebook.com/Velmie-2009413629297249',
];
const NETWORK_ICON_SIZE = 0.2;

export function renderFirstPage(doc, quoteName, quoteValidDate) {
  doc.setFillColor(BLUE_COLOR);
  doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), 'F');

  // images
  doc.addImage(
    logo, "PNG",
    NORMAL_FIRST_PAGE_PADDING_LEFT, NORMAL_FIRST_PAGE_LOGO_PADDING_TOP,
    0.13, 0.56,
  );
  doc.addImage(
    lines, "PNG",
    doc.internal.pageSize.getWidth() - NORMAL_FIRST_PAGE_PADDING_LEFT,
    doc.internal.pageSize.getHeight() / 2 - NORMAL_FIRST_PAGE_LINES_PADDING_CENTER,
    0.35, 0.1,
  );

  // name
  doc.setTextColor(WHITE_COLOR);
  doc.setFont('Montserrat', 'bold');
  doc.setFontSize(MONTSERRAT_PROJECT_TITLE);
  doc.text(
    quoteName,
    NORMAL_FIRST_PAGE_PADDING_LEFT,
    doc.internal.pageSize.getHeight() / 2 - NORMAL_FIRST_PAGE_LINES_PADDING_CENTER,
    { lineHeightFactor: LINE_HEIGHT },
  );

  // proposal
  doc.setFont('GothamPro', 'light');
  doc.setFontSize(GOTHAM_PRO_PROJECT_DETAILS);
  doc.text(
    'Proposal',
    NORMAL_FIRST_PAGE_PADDING_LEFT,
    doc.internal.pageSize.getHeight() / 2 + 0.1,
    { lineHeightFactor: LINE_HEIGHT },
  );

  // valid data
  doc.setFontSize(GOTHAM_PRO_PROJECT_DATA);
  doc.text(
    `Valid until ${quoteValidDate}`,
    NORMAL_FIRST_PAGE_PADDING_LEFT,
    doc.internal.pageSize.getHeight() - NORMAL_FIRST_PAGE_LOGO_PADDING_TOP,
    { lineHeightFactor: LINE_HEIGHT },
  );
}

export function renderProductSectionTitlePage(doc, section_name) {
  doc.setTextColor(BLACK_COLOR);
  doc.setFont('Montserrat', 'extra-bold');
  doc.setFontSize(MONTSERRAT_PRODUCT_SECTION_TITLE);

  doc.text(
    section_name,
    doc.internal.pageSize.getWidth() / 2,
    doc.internal.pageSize.getHeight() / 2,
    { align: 'center', lineHeightFactor: LINE_HEIGHT },
  );

  const arrowLogoSize = 0.47;

  doc.addImage(
    arrow, "PNG",
    (doc.internal.pageSize.getWidth() / 2) - arrowLogoSize / 2,
    (doc.internal.pageSize.getHeight() / 2) + pointsToInches(MONTSERRAT_PRODUCT_SECTION_TITLE),
    arrowLogoSize,
    arrowLogoSize,
  );

  doc.addPage();
}

export function renderProductSubsectionUpperTitle(doc, subsection_name, paddingLeft, paddingTop) {
  doc.setTextColor(BLACK_COLOR);
  doc.setFont('Montserrat', 'bold');
  doc.setFontSize(MONTSERRAT_PRODUCT_SUBSECTION_TITLE);

  doc.text(
    subsection_name,
    paddingLeft,
    paddingTop + pointsToInches(MONTSERRAT_PRODUCT_SUBSECTION_TITLE),
    { lineHeightFactor: LINE_HEIGHT },
  );
}

export function renderProductSubsectionMediumTitle(doc, subsection_name, paddingLeft, paddingTop, paddingRight) {
  const [, mediumTitleHeight] = addText(
    doc,
    subsection_name,
    paddingLeft, paddingTop,
    {
      font: 'Montserrat',
      fontWeight: 'extra-bold',
      fontSize: 20,
      customLineHeight: 1.2,
      maxLineWidth: doc.internal.pageSize.getWidth() - paddingRight,
    }
  );

  return mediumTitleHeight;
}

export function renderProductSubsectionMediumContent(doc, subsection_content, paddingLeft, paddingTop, paddingRight) {
  addHTMLText(
    doc,
    subsection_content,
    paddingLeft, paddingTop,
    {
      font: 'GothamPro',
      fontWeight: 'normal',
      fontSize: GOTHAM_PRO_PRODUCT_SECTION_CONTENT,
      maxLineWidth: doc.internal.pageSize.getWidth() - paddingRight,
    },
  );
}

export function renderLeftDecorations(doc, largePaddingLeft, normalLogoPaddingTop, normalLinesPaddingFromCenter) {
  const linesWidth = 0.28;

  doc.addImage(
    logoDark, "PNG",
    largePaddingLeft / 3 - linesWidth / 2,
    normalLogoPaddingTop,
    0.12, 0.52,
  );

  doc.addImage(
    linesDark, "PNG",
    largePaddingLeft / 3 - linesWidth / 2,
    doc.internal.pageSize.getHeight() / 2 + normalLinesPaddingFromCenter,
    linesWidth, 0.08,
  );
}

export function renderSocialNetworkBlock(doc) {
  let xIconPadding = 0;
  NETWORK_ICONS.forEach((iconUrl, iconIndex) => {
    doc.addImage(
      iconUrl, "PNG",
      doc.internal.pageSize.getWidth() - 1.6 + xIconPadding, 0.85,
      NETWORK_ICON_SIZE, NETWORK_ICON_SIZE,
    );

    doc.link(
      doc.internal.pageSize.getWidth() - 1.6 + xIconPadding, 0.85,
      NETWORK_ICON_SIZE, NETWORK_ICON_SIZE,
      { url: NETWORK_URLS[iconIndex] },
    );

    xIconPadding += (NETWORK_ICON_SIZE + 0.06);
  });
}

export function renderSplitLine(doc, paddingLeft, paddingRight) {
  doc.setLineWidth(0.015);
  doc.setDrawColor(BLACK_COLOR);
  doc.line(
    paddingLeft,
    doc.internal.pageSize.getHeight() / 2,
    doc.internal.pageSize.getWidth() - paddingRight,
    doc.internal.pageSize.getHeight() / 2,
  );
}
