import React, { useState } from "react";

import Gantt from '../Gantt/Gantt';
import GanttToolbar from "../GanttToolbar/GanttToolbar";

const GANTT_ZOOM_CONFIGURATIONS = {
  levels: [
    {
      name: 'Days',
      scale_height: 60,
      min_column_width: 70,
      scales: [
        { unit: 'week', step: 1, format: 'Week #%W' },
        { unit: 'day', step: 1, format: '%d %M' }
      ]
    },
    {
      name: 'Months',
      scale_height: 60,
      min_column_width: 70,
      scales: [
        { unit: "month", step: 1, format: '%F' },
        { unit: 'week', step: 1, format: '#%W' }
      ]
    }
  ]
};

function GanttEditor(props) {
  const {
    content,
    setContent,
  } = props;

  const [currentZoom, setCurrentZoom] = useState('Months');

  const onChange = (oldContent, type, action, item, id) => {
    const rootTask = oldContent.data.find(e => e.parent === 0);

    if (type === "task") {
      switch (action) {
        case "create":
          setContent({
            ...oldContent,
            data: [
              ...oldContent.data,
              {
                ...item,
                parent: parseInt(item.parent),
                open: parseInt(item.parent) === rootTask.id,
              },
            ]
          });
          break;
        case "update":
          setContent({
            ...oldContent,
            data: [
              ...oldContent.data.filter(element => element.id !== id),
              item,
            ],
          });
          break;
        case "delete":
          setContent({
            ...oldContent,
            data: oldContent.data.filter(element => element.id !== id),
          });
          break;
      }
    }
  };

  return (
    <div>
      <GanttToolbar
        zoom={currentZoom}
        zoomOptions={GANTT_ZOOM_CONFIGURATIONS.levels.map(l => l.name)}
        onZoomChange={setCurrentZoom}
      />
      <Gantt
        data={content}
        zoom={currentZoom}
        zoomConfig={GANTT_ZOOM_CONFIGURATIONS}
        onChange={onChange}
      />
    </div>
  );
}

export default GanttEditor;
