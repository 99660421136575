export function filterGanttData(data, parentId, result = []) {
  data.forEach(element => {
    if (element.parent === parentId) {
      result.push(element);
      filterGanttData(data, element.id, result);
    }
  });

  return result;
}
