import React from "react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import htmlParser from "html-react-parser";

import { range } from "../../utils/helpers/array";
import { assetToBase64 } from "../../utils/helpers/file";
import { subtract as subtractDates } from "../../utils/helpers/date";
import { filterGanttData } from "../../utils/helpers/gantt-content";
import {
  addText,
  addHTMLText,
  pointsToInches,
  renderHTMLTextComponents,
} from "../../utils/pdf/text-formatting";
import {
  getHeaders,
  getRows,
} from "../../utils/pdf/table-formatting";
import {
  renderProductSectionTitlePage,
  renderProductSubsectionUpperTitle,
  renderProductSubsectionMediumTitle,
  renderProductSubsectionMediumContent,
  renderLeftDecorations,
  renderSocialNetworkBlock,
  renderSplitLine,
  renderFirstPage,
} from "../../utils/pdf/component-rendering";
import {
  WHITE_COLOR,
  WHITE_BLUE_COLOR,
  BLUE_COLOR,
  BLACK_COLOR,
  LINE_HEIGHT,
  TIMELINE_LINE_HEIGHT,
  GOTHAM_PRO_PROJECT_DATA,
  GOTHAM_PRO_PRODUCT_SECTION_CONTENT,
  NORMAL_FIRST_PAGE_LOGO_PADDING_TOP,
  NORMAL_FIRST_PAGE_LINES_PADDING_CENTER,
  SMALL_PADDING_LEFT,
  LARGE_PADDING_LEFT,
  LARGE_PADDING_RIGHT,
  NORMAL_PADDING_BOTTOM,
  NORMAL_SUBSECTION_PADDING_TOP,
  MONTSERRAT_PRODUCT_SUBSECTION_TITLE,
  NORMAL_SUBSECTION_PADDING_BOTTOM,
  DEFAULT_PAGE_MARGIN,
} from "../../utils/pdf/constants";

import GothamProLight from '../../../public/fonts/GothamPro-Light.ttf';
import GothamPro from '../../../public/fonts/GothamPro.ttf';
import GothamProMedium from '../../../public/fonts/GothamPro-Medium.ttf';
import GothamProBolt from '../../../public/fonts/GothamPro-Bold.ttf';
import InterMedium from '../../../public/fonts/Inter-Medium.ttf';
import InterBold from '../../../public/fonts/Inter-Bold.ttf';
import MontserratBold from '../../../public/fonts/Montserrat-Bold.ttf';
import MontserratExtraBold from '../../../public/fonts/Montserrat-ExtraBold.ttf';

import imgBg from '../../../public/images/img-bg.jpg';

import icon1 from '../../../public/images/icon_1.png';
import icon2 from '../../../public/images/icon_2.png';
import icon3 from '../../../public/images/icon_3.png';
import icon4 from '../../../public/images/icon_4.png';
import icon5 from '../../../public/images/icon_5.png';
import icon6 from '../../../public/images/icon_6.png';
import marker from '../../../public/images/marker.png';

const BASE_CONTENT_ICONS = [icon1, icon2, icon3, icon4, icon5, icon6];

export default function WalletQuotePdfPrinter(props) {
  const {
    quote,
  } = props;

  const generate = async () => {
    const doc = new jsPDF({ orientation: "landscape", unit: 'in' });

    // Define fonts for pdf.js
    doc.addFileToVFS("GothamPro-Light.ttf", await assetToBase64(GothamProLight));
    doc.addFileToVFS("GothamPro.ttf", await assetToBase64(GothamPro));
    doc.addFileToVFS("GothamPro-Medium.ttf", await assetToBase64(GothamProMedium));
    doc.addFileToVFS("GothamPro-Bold.ttf", await assetToBase64(GothamProBolt));
    doc.addFileToVFS("Inter-Medium.ttf", await assetToBase64(InterMedium));
    doc.addFileToVFS("Inter-Bold.ttf", await  assetToBase64(InterBold));
    doc.addFileToVFS("Montserrat-Bold.ttf", await assetToBase64(MontserratBold));
    doc.addFileToVFS("Montserrat-ExtraBold.ttf", await assetToBase64(MontserratExtraBold));

    doc.addFont("GothamPro-Light.ttf", "GothamPro", "light");
    doc.addFont("GothamPro.ttf", "GothamPro", "normal");
    doc.addFont("GothamPro-Medium.ttf", "GothamPro", "medium");
    doc.addFont("GothamPro-Bold.ttf", "GothamPro", "bold");
    doc.addFont("Inter-Medium.ttf", "Inter", "medium");
    doc.addFont("Inter-Bold.ttf", "Inter", "bold");
    doc.addFont("Montserrat-Bold.ttf", "Montserrat", "bold");
    doc.addFont("Montserrat-ExtraBold.ttf", "Montserrat", "extra-bold");

    const startPositionAfterSubsectionTitle = NORMAL_SUBSECTION_PADDING_TOP
      + pointsToInches(MONTSERRAT_PRODUCT_SUBSECTION_TITLE)
      + NORMAL_SUBSECTION_PADDING_BOTTOM;
    const smallLineWidth = doc.internal.pageSize.getWidth() - (LARGE_PADDING_LEFT + LARGE_PADDING_RIGHT);
    const largeLineWidth = doc.internal.pageSize.getWidth() - (SMALL_PADDING_LEFT * 2);

    const validDate = new Date(Date.parse(quote.valid_date));
    const validDateFormattingOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const quoteValidDate = validDate.toLocaleDateString("en-US", validDateFormattingOptions);

    renderFirstPage(doc, quote.name, quoteValidDate);
    doc.addPage();

    // List of content
    doc.addPage();

    const listOfContent = [];
    let pageNumbersForDecorate = [];

    // Main Content
    const dependent_product_sections = quote.dependent_product_sections
      .sort((a, b) => a.sequence - b.sequence);
    dependent_product_sections.forEach((productSection, sectionIndex) => {
      if (productSection.name && productSection.name !== '') {
        listOfContent.push({
          type: 'product_section',
          content: productSection.name,
          pageNumber: doc.internal.getCurrentPageInfo().pageNumber,
        });
        pageNumbersForDecorate.push(doc.internal.getCurrentPageInfo().pageNumber);
        renderProductSectionTitlePage(doc, productSection.name);
      }

      const dependent_product_subsections = productSection
        .dependent_product_subsections
        .sort((a, b) => a.sequence - b.sequence);

      const product_subsection_paddings = dependent_product_subsections.map(dProductSubsection => {
        const tables = dProductSubsection.dependent_product_section_contents
          .sort((a, b) => a.sequence - b.sequence)
          .filter(content => content.content_type === 'html_table');

        if (tables.length > 0) {
          return [SMALL_PADDING_LEFT, largeLineWidth, false];
        } else {
          return [LARGE_PADDING_LEFT, smallLineWidth, true];
        }
      });

      // subsections
      dependent_product_subsections.forEach((dProductSubsection, subsectionIndex) => {
        const [
          paddingLeft,
          lineWidth,
          writePageNumbersForDecorate,
        ] = product_subsection_paddings[subsectionIndex];
        const startProductSectionPage = doc.internal.getCurrentPageInfo().pageNumber;
        let lastYContentPosition;

        listOfContent.push({
          type: 'product_subsection',
          content: dProductSubsection.name,
          pageNumber: doc.internal.getCurrentPageInfo().pageNumber,
        });

        // render subsection title
        if (dProductSubsection.content_type !== 'medium') {
          renderProductSubsectionUpperTitle(
            doc, dProductSubsection.name, paddingLeft, NORMAL_SUBSECTION_PADDING_TOP);
        }

        // subsection main content
        if (dProductSubsection.content_type === 'usual') {
          const dependent_product_section_contents = dProductSubsection
            .dependent_product_section_contents
            .sort((a, b) => a.sequence - b.sequence);

          dependent_product_section_contents.forEach((content) => {
            let currentYPosition;

            if (!lastYContentPosition) {
              currentYPosition = startPositionAfterSubsectionTitle;
            } else {
              currentYPosition = lastYContentPosition + NORMAL_PADDING_BOTTOM;
            }

            if (content.content_type === 'text' || content.content_type === 'pretty_list') {
              lastYContentPosition = addHTMLText(
                doc,
                content.text_content,
                paddingLeft,
                currentYPosition,
                {
                  font: 'GothamPro',
                  fontWeight: 'normal',
                  fontSize: GOTHAM_PRO_PRODUCT_SECTION_CONTENT,
                  maxLineWidth: lineWidth,
                },
              );
            } else if (content.content_type === 'html_table') {
              doc.autoTable({
                head: getHeaders(content.table_content.headers),
                body: getRows(content.table_content.rows, content.table_style),
                startY: currentYPosition,
                rowPageBreak: 'avoid',
                styles: {
                  textColor: [34, 34, 34],
                  cellPadding: 0.12,
                  lineColor: [238, 238, 238],
                  lineWidth: 0.01,
                },
                headStyles: {
                  font: 'Inter',
                  fontStyle: content.table_style === 'strict' ? 'medium' : 'bold',
                },
                bodyStyles: {
                  valign: 'top',
                  font: 'GothamPro',
                  fontStyle: 'normal',
                  fontSize: GOTHAM_PRO_PRODUCT_SECTION_CONTENT,
                },
              });

              lastYContentPosition = doc.lastAutoTable.finalY;
            } else if (content.content_type === 'gantt_chart') {
              const canvas = document.createElement("canvas");
              canvas.setAttribute("width","1000");
              canvas.setAttribute("height","650");
              const ctx = canvas.getContext('2d');

              const rootElement = content.gantt_content.data.find(e => e.parent === 0);
              const rootStartDate = rootElement.start_date;
              const rootDuration = rootElement.duration;
              const ganttData = filterGanttData(content.gantt_content.data, rootElement.id);

              const roundRadius = 12.5;
              const timeLineYPadding = 5;
              const timeLineMainFontSize = 16;
              const timeLineDurationFontSize = 14;
              const timeLineStartPoint = canvas.width / 2;
              const timeLineEndPoint = canvas.width - roundRadius;
              const timeLineWidth = timeLineEndPoint - timeLineStartPoint;

              let timelineYPoint = timeLineMainFontSize;
              ganttData.forEach((element) => {
                if (element.id !== 1) {
                  // main text content
                  ctx.font = `${timeLineMainFontSize}px Arial`;
                  ctx.fillStyle = element.open ? BLUE_COLOR : BLACK_COLOR;
                  ctx.fillText(
                    element.open ? element.text : `- ${element.text}`,
                    0, timelineYPoint,
                  );

                  if (!element.open) {
                    // draw time lines
                    ctx.beginPath();
                    ctx.moveTo(timeLineStartPoint, timelineYPoint - timeLineYPadding);
                    ctx.lineTo(timeLineEndPoint, timelineYPoint - timeLineYPadding);
                    ctx.lineWidth = roundRadius * 2;
                    ctx.strokeStyle = WHITE_BLUE_COLOR;
                    ctx.lineCap = 'round';
                    ctx.stroke();

                    const startDay = subtractDates(element.start_date, rootStartDate);

                    const startPercentage = startDay / rootDuration;
                    const defaultStartXPoint = timeLineStartPoint + timeLineWidth * startPercentage;
                    const elementStartXPoint = startPercentage === 0 ? defaultStartXPoint : defaultStartXPoint + roundRadius;

                    const endPercentage = (startDay + element.duration) / rootDuration;
                    const defaultEndXPoint = timeLineStartPoint + timeLineWidth * endPercentage;
                    const elementEndXPoint = endPercentage === 1 ? defaultEndXPoint : defaultEndXPoint - roundRadius;

                    ctx.beginPath();
                    ctx.moveTo(elementStartXPoint, timelineYPoint - timeLineYPadding);
                    ctx.lineTo(elementEndXPoint, timelineYPoint - timeLineYPadding);
                    ctx.lineWidth = roundRadius * 2;
                    ctx.strokeStyle = BLUE_COLOR;
                    ctx.lineCap = 'round';
                    ctx.stroke();

                    // duration text
                    const durationLabelText = element.duration > 1 ? `${element.duration} days` : 'day';
                    const durationLabelWidth = ctx.measureText(durationLabelText).width;
                    const durationPadding = (elementEndXPoint - elementStartXPoint - durationLabelWidth) / 2;

                    ctx.font = `bold ${timeLineDurationFontSize}px Arial`;
                    ctx.fillStyle = WHITE_COLOR;
                    ctx.fillText(durationLabelText, elementStartXPoint + durationPadding, timelineYPoint);
                  }

                  timelineYPoint += timeLineMainFontSize * TIMELINE_LINE_HEIGHT;
                }
              });

              const canvasImg = canvas.toDataURL("image/png", 1.0);
              doc.addImage(
                canvasImg, "PNG",
                paddingLeft, currentYPosition,
                doc.internal.pageSize.getWidth() - LARGE_PADDING_LEFT - LARGE_PADDING_RIGHT,
                doc.internal.pageSize.getHeight() - currentYPosition - DEFAULT_PAGE_MARGIN,
              );
            }

          });
        } else if (dProductSubsection.content_type === 'medium') {
          // overview
          const imgBgSize = 4.5;
          const paddingRight = LARGE_PADDING_LEFT * 2 + imgBgSize;

          const mediumTitleHeight = renderProductSubsectionMediumTitle(
            doc, dProductSubsection.name, LARGE_PADDING_LEFT, 2.5, paddingRight);
          renderProductSubsectionMediumContent(
            doc, dProductSubsection.dependent_product_section_contents[0].text_content,
            LARGE_PADDING_LEFT, 2 + mediumTitleHeight + NORMAL_PADDING_BOTTOM, paddingRight);

          doc.addImage(
            imgBg,
            "JPEG",
            doc.internal.pageSize.getWidth() - imgBgSize, 0,
            imgBgSize, doc.internal.pageSize.getHeight()
          );

        } else if (dProductSubsection.content_type === 'pretty_list') {
          const iconSize = 0.47;
          const centralMargin = 0.8;

          let yItemPadding = 0;
          let xItemPadding = 0;
          dProductSubsection.base_contents
            .sort((a, b) => a.sequence - b.sequence)
            .forEach((base, baseIndex) => {
              const itemTextWidth = (
                doc.internal.pageSize.getWidth() - ((LARGE_PADDING_LEFT + iconSize + 0.2) * 2)
              ) / 2 - centralMargin / 2;

              const [, itemTitleHeight] = addText(
                doc,
                base.title,
                LARGE_PADDING_LEFT + xItemPadding + iconSize + 0.2,
                2.3 + yItemPadding,
                {
                  font: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: 11,
                  maxLineWidth: itemTextWidth,
                }
              );

              doc.addImage(
                BASE_CONTENT_ICONS[baseIndex],
                "PNG",
                LARGE_PADDING_LEFT + xItemPadding,
                2.3 + yItemPadding - itemTitleHeight,
                iconSize, iconSize,
              );

              yItemPadding += (itemTitleHeight + NORMAL_PADDING_BOTTOM * 0.7);

              const [, itemContentHeight] = addText(
                doc,
                base.content,
                LARGE_PADDING_LEFT + xItemPadding + iconSize + 0.2,
                2.3 + yItemPadding,
                {
                  font: 'GothamPro',
                  fontWeight: 'normal',
                  fontSize: GOTHAM_PRO_PRODUCT_SECTION_CONTENT,
                  maxLineWidth: itemTextWidth,
                }
              );

              yItemPadding += (itemContentHeight + NORMAL_PADDING_BOTTOM * 2);

              if (baseIndex === 2) {
                xItemPadding += centralMargin + itemTextWidth + iconSize + 0.2;
                yItemPadding = 0;
              }
            });

        } else if (dProductSubsection.content_type === 'contact_info') {
          // networks block
          renderSocialNetworkBlock(doc);
          // contact info block
          const [, phoneBlockHeight] = renderHTMLTextComponents(
            doc,
            htmlParser(`<b>Phone:</b> ${dProductSubsection.contact_info.phone}`),
            LARGE_PADDING_LEFT,
            doc.internal.pageSize.getHeight() / 2 - 0.7,
            {
              font: 'GothamPro',
              fontWeight: 'normal',
              fontSize: 15,
              maxLineWidth: smallLineWidth,
            }
          );
          renderHTMLTextComponents(
            doc,
            htmlParser(`<b>Email:</b> ${dProductSubsection.contact_info.email}`),
            LARGE_PADDING_LEFT,
            doc.internal.pageSize.getHeight() / 2 - 0.7 + phoneBlockHeight + 0.07,
            {
              font: 'GothamPro',
              fontWeight: 'normal',
              fontSize: 15,
              maxLineWidth: smallLineWidth,
            }
          );

          renderSplitLine(doc, LARGE_PADDING_LEFT, LARGE_PADDING_RIGHT);

          // contact addresses
          const markerSize = 0.07;
          const markerPadding = 0.7;
          const locationFontSize = 10.5;

          let xPadding = 0;
          dProductSubsection.product_subsection.product_contact_addresses
            .sort((a, b) => a.id - b.id)
            .forEach((address, addressIndex) => {
              doc.addImage(
                marker,
                "PNG",
                LARGE_PADDING_LEFT + xPadding,
                doc.internal.pageSize.getHeight() / 2 + markerPadding,
                markerSize, markerSize,
              );

              doc.setFont('GothamPro', 'bold');
              doc.setFontSize(locationFontSize);
              doc.text(
                address.country,
                LARGE_PADDING_LEFT + markerSize + xPadding,
                doc.internal.pageSize.getHeight() / 2 + 1.05,
                { lineHeightFactor: LINE_HEIGHT },
              );

              doc.setFont('GothamPro', 'normal');
              doc.setFontSize(locationFontSize);
              doc.text(
                address.street,
                LARGE_PADDING_LEFT + markerSize + xPadding,
                doc.internal.pageSize.getHeight() / 2 + 1.3 + pointsToInches(locationFontSize),
                { lineHeightFactor: LINE_HEIGHT },
              );

              doc.text(
                `${address.city}, ${address.post_code}`,
                LARGE_PADDING_LEFT + markerSize + xPadding,
                doc.internal.pageSize.getHeight() / 2 + 1.35 + pointsToInches(locationFontSize) + pointsToInches(locationFontSize),
                { lineHeightFactor: LINE_HEIGHT },
              );

              if (addressIndex === dProductSubsection.product_subsection.product_contact_addresses.length - 2) {
                // for Belarus address
                xPadding += 2;
              } else {
                xPadding += 2.6;
              }
            });
        }

        if (writePageNumbersForDecorate) {
          pageNumbersForDecorate = [
            ...pageNumbersForDecorate,
            ...range(startProductSectionPage, doc.internal.getCurrentPageInfo().pageNumber),
          ];
        }

        if (productSection.dependent_product_subsections.length - 1 !== subsectionIndex) {
          doc.addPage();
        }
      });

      if (dependent_product_sections.length - 1 !== sectionIndex) {
        doc.addPage();
      }
    });

    // list of content
    doc.setPage(2);
    pageNumbersForDecorate.push(doc.internal.getCurrentPageInfo().pageNumber);
    renderProductSubsectionUpperTitle(
      doc, 'Contents', LARGE_PADDING_LEFT, NORMAL_SUBSECTION_PADDING_TOP);

    let contentPosition = startPositionAfterSubsectionTitle;
    listOfContent.forEach((title, index) => {
      let content, fontWeight, additionalPadding;
      if (title.type === 'product_section' || index === 0) {
        content = title.content;
        fontWeight = 'bold';
        additionalPadding = 0;
      } else {
        content = `-${title.content}`;
        fontWeight = 'normal';
        additionalPadding = 0.25;
      }

      const [, height] = addText(
        doc,
        content,
        LARGE_PADDING_LEFT + additionalPadding,
        contentPosition,
        {
          font: 'GothamPro',
          fontWeight: fontWeight,
          fontSize: GOTHAM_PRO_PRODUCT_SECTION_CONTENT,
          maxLineWidth: largeLineWidth,
        }
      );

      // page number
      doc.text(
        title.pageNumber.toString(),
        doc.internal.pageSize.getWidth() - LARGE_PADDING_RIGHT,
        contentPosition,
        { lineHeightFactor: LINE_HEIGHT },
      );

      contentPosition += height * 1.2;
    });

    // Disclaimer
    addHTMLText(
      doc, quote.dependent_product_disclaimer_section.content,
      LARGE_PADDING_LEFT,
      contentPosition + 0.3,
      {
        font: 'GothamPro',
        fontWeight: 'normal',
        fontSize: GOTHAM_PRO_PROJECT_DATA,
        maxLineWidth: smallLineWidth,
      },
    );

    // decorate pages
    pageNumbersForDecorate = [...new Set(pageNumbersForDecorate)];
    for (let index = 0; index < pageNumbersForDecorate.length; index++) {
      doc.setPage(pageNumbersForDecorate[index]);
      renderLeftDecorations(
        doc,
        LARGE_PADDING_LEFT, NORMAL_FIRST_PAGE_LOGO_PADDING_TOP,
        NORMAL_FIRST_PAGE_LINES_PADDING_CENTER,
      );
    }

    // page numbering
    doc.setFont('GothamPro', 'bold');
    doc.setFontSize(GOTHAM_PRO_PRODUCT_SECTION_CONTENT);

    const horizontalPos = DEFAULT_PAGE_MARGIN / 2;
    const verticalPos = doc.internal.pageSize.getHeight() - (DEFAULT_PAGE_MARGIN / 2);

    for (let pageNumber = 3; pageNumber < doc.internal.getNumberOfPages() + 1 ; pageNumber++) {
      doc.setPage(pageNumber);
      doc.text(
        pageNumber.toString(),
        horizontalPos, verticalPos,
        { lineHeightFactor: LINE_HEIGHT },
      );
    }

    doc.save(`${quote.name}.pdf`);
  };

  return (
    <button className="btn" onClick={() => generate()}>PDF</button>
  );
};
