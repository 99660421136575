import React from "react";

import htmlParser from 'html-react-parser';

function TablePainter(props) {
  const {
    content,
  } = props;

  return (
    <>
      {
        (content && Object.keys(content || {}).length > 0) &&
        <table className="table table-bordered">
          <tbody>
          {
            content.headers &&
            <tr>
              {
                content.headers.map((header, index) => htmlParser(header) )
              }
            </tr>
          }
          {
            content.rows &&
            <>
              {
                content.rows.map((row, index) => {
                  return (
                    <tr>
                      {
                        row.map((cell, index) => htmlParser(cell))
                      }
                    </tr>
                  );
                })
              }
            </>
          }
          </tbody>
        </table>
      }
    </>
  );
}

export default TablePainter;
