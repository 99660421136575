import React, { useRef, useState } from "react";

import "bootstrap";

function ContactInfo(props) {
  const {
    contactInfo,
    subsectionId,
    subsectionName,
    productContactInfoRefs,
    fields,
  } = props;

  productContactInfoRefs.id = useRef();

  return (
    <div className="row">
      <input
        id={`${subsectionId}_contact_info_attributes_id`}
        name={`${subsectionName}[contact_info_attributes][id]`}
        type="hidden"
        ref={productContactInfoRefs.id}
        value={contactInfo.id}
      />

      <div className="form-group">
        {
          fields.map(fieldObj => {
            const contactInfoId = `${subsectionId}_contact_info_attributes_${fieldObj.property}`;
            const contactInfoName = `${subsectionName}[contact_info_attributes][${fieldObj.property}]`;

            const [field, setField] = useState(contactInfo[fieldObj.property]);
            productContactInfoRefs[fieldObj.property] = useRef();

            return(
              <div className="field col-sm-6">
                <label className="form-label" htmlFor={contactInfoName}>
                  {fieldObj.name}
                </label>
                <input id={contactInfoId}
                       className="form-control"
                       name={contactInfoName}
                       type="text"
                       ref={productContactInfoRefs[fieldObj.property]}
                       value={field}
                       onChange={ (event) => setField(event.target.value) }/>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default ContactInfo;
