// general
export const LINE_HEIGHT = 1.6;
export const TIMELINE_LINE_HEIGHT = 2.2;

// color constants
export const WHITE_COLOR = '#FFF';
export const WHITE_BLUE_COLOR = '#E8EDFD';
export const BLUE_COLOR = '#0037FF';
export const BLACK_COLOR = '#222';

// font size constants
export const MONTSERRAT_PROJECT_TITLE = 34;
export const GOTHAM_PRO_PROJECT_DETAILS = 12;
export const GOTHAM_PRO_PROJECT_DATA = 9;
export const MONTSERRAT_PRODUCT_SECTION_TITLE = 42;
export const MONTSERRAT_PRODUCT_SUBSECTION_TITLE = 33;
export const GOTHAM_PRO_PRODUCT_SECTION_CONTENT = 10;

// padding constants
export const NORMAL_FIRST_PAGE_PADDING_LEFT = 0.9;
export const NORMAL_FIRST_PAGE_LOGO_PADDING_TOP = 0.5;
export const NORMAL_FIRST_PAGE_LINES_PADDING_CENTER = 0.3;
export const SMALL_PADDING_LEFT = 0.57;
export const LARGE_PADDING_LEFT = SMALL_PADDING_LEFT * 3;
export const LARGE_PADDING_RIGHT = LARGE_PADDING_LEFT * 0.5;
export const NORMAL_PADDING_BOTTOM = 0.23;
export const NORMAL_SUBSECTION_PADDING_TOP = 0.5;
export const NORMAL_SUBSECTION_PADDING_BOTTOM = 0.7;

// margins
export const DEFAULT_PAGE_MARGIN = 0.556;
