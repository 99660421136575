import React from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

import './Gantt.css';

export default class Gantt extends React.Component {
  constructor(props) {
    super(props);
    this.initZoom();
  }

  // instance of gantt.dataProcessor
  dataProcessor = null;

  initZoom() {
    gantt.ext.zoom.init(this.props.zoomConfig);
  }

  setZoom(value) {
    gantt.ext.zoom.setLevel(value);
  }

  initGanttDataProcessor() {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    const onChange = this.props.onChange;
    this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise((resolve) => {
        if (onChange) {
          onChange(this.props.data, type, action, item, id);
        }

        return resolve();
      });
    });
  }

  shouldComponentUpdate(nextProps) {
    return this.props.zoom !== nextProps.zoom;
  }

  componentDidMount() {
    gantt.config.xml_date = "%Y-%m-%d";
    gantt.init(this.ganttContainer);
    this.initGanttDataProcessor();
    gantt.parse(this.props.data);
  }

  componentWillUnmount() {
    if (this.dataProcessor) {
      this.dataProcessor.destructor();
      this.dataProcessor = null;
    }
  }

  render() {
    const { zoom } = this.props;
    this.setZoom(zoom);

    return (
      <div className="gantt-container">
        <div
          ref={input => { this.ganttContainer = input }}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    );
  }
}
