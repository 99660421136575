function blobToBase64(blob) {
  return new Promise(resolve => {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      resolve(base64String);
    };
    reader.readAsDataURL(blob);
  });
}

export async function assetToBase64(globalAssetName) {
  const response = await fetch(globalAssetName);
  const blob = await response.blob();
  return blobToBase64(blob);
}
