import React, { useState } from "react";
import htmlParser from 'html-react-parser';

import "bootstrap";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import { updateProductDisclaimerSectionHandler } from "../../api/request-builder";

export default function EditProductDisclaimerSection(props) {
  const {
    product_disclaimer_section,
  } = props;

  const [disclaimer, setDisclaimer] = useState(product_disclaimer_section.content);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateProductDisclaimerSectionHandler(disclaimer);
  };

  return(
    <>
      <form onSubmit={handleSubmit}>
        <ReactSummernote
          options={{
            height: 350,
            dialogsInBody: true,
            toolbar: [
              ["style", []],
              ["font", ["bold", "italic", "underline"]],
              ["para", ["ul", "ol"]],
              ["insert", []],
              ["view", ["codeview"]]
            ]
          }}
          onChange={ (content) => setDisclaimer(content) }>
          {htmlParser(disclaimer)}
        </ReactSummernote>

        <div className="actions">
          <button className="btn btn-primary" name="button" type="submit">Save</button>
        </div>
      </form>
    </>
  );
}
