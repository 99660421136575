export const cleanHTML = (str) => {
  return deleteUnusedSpans(
    deleteTransparent(
      boltFormatting(
        deleteUnusedLists(
          deleteUnusedParagraphs(
            deleteUnusedBold(
              deleteUnusedBreaks(
                str,
              )
            )
          )
        )
      )
    )
  );
};

const boltFormatting = (str) => {
  return str.replace(
    /(<span style=\"font-weight: 700;\">)([^<\/span>]*)(<\/span>)/g,
    (_all, _spanStart, content, _spanEnd) => `<b>${content}</b>`,
  );
};

const deleteTransparent = (str) => {
  return str.replace(
    /(<span style=\"background-color: transparent;\">)([^<\/span>]*)/g,
    (_all, _spanStart, content) => content,
  );
};

const deleteUnusedSpans = (str) => {
  return str.replace(/(<\/span>)/g, '');
};

const deleteUnusedLists = (str) => {
  return str.replace(/(<ol>)(\s*)(<\/ol>)/g, '');
};

const deleteUnusedParagraphs = (str) => {
  return str.replace(/(<p>)(\s*)(<\/p>)/g, '');
};

const deleteUnusedBold = (str) => {
  return str.replace(/(<b>)(\s*)(<\/b>)/g, '');
};

const deleteUnusedBreaks = (str) => {
  return str.replace(/(<br>)/g, '');
};
