import React, { useState, useRef } from "react";
import htmlParser from 'html-react-parser';

import "bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";

import GanttEditor from "../UI/GanttEditor/GanttEditor";
import TableEditor from "../UI/TableEditor/TableEditor";
import { getFormData } from "../../utils/product-section-content";
import { cleanHTML } from "../../utils/helpers/html-formatting";
import { updateWalletQuoteHandler } from "../../api/request-builder";
import ProductSubsectionPrettyList from "../UI/Product/Subsection/PrettyList";
import ProductSubsectionContactInfo from "../UI/Product/Subsection/ContactInfo";

export default function EditWalletQuote(props) {
  const {
    quote,
  } = props;

  const dependent_product_sections = quote
    .dependent_product_sections
    .sort((a, b) => a.sequence - b.sequence);

  const walletQuoteNameInputRef = useRef();
  const [walletQuoteName, setWalletQuoteName] = useState(quote.name);

  const walletQuoteValidDateParameterName = 'wallet_quote[valid_date]';
  const [walletQuoteValidDate, setWalletQuoteValidDate] = useState(
    new Date(Date.parse(quote.valid_date))
  );

  const walletQuoteDependentProductSectionIdInputRefs = [];
  const walletQuoteDependentProductSectionContentTypeInputRefs = [];
  const walletQuoteDependentProductSectionProductSectionIdRefs = [];
  const walletQuoteDependentProductSubsectionIdRefs = [];
  const walletQuoteDependentProductSubsectionProductSubsectionIdRefs = [];

  const walletQuoteDependentProductSectionContentIdInputRefs = [];
  const walletQuoteDependentProductSectionContentContentTypeInputRefs = [];
  const walletQuoteDependentProductSectionContentContentIdInputRefs = [];
  const walletQuoteDependentProductSectionContentTableStyleRefs = [];

  const walletQuoteDependentProductSectionContentKeys = [];
  const walletQuoteDependentProductSectionContents = [];
  const setWalletQuoteDependentProductSectionContents = [];

  const productBaseSectionRefs = {
    id: [],
    title: [],
    content: [],
  };
  const productContactInfoRefs = {
    id: undefined,
    phone: undefined,
    email: undefined,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateWalletQuoteHandler(
      quote, dependent_product_sections,
      walletQuoteNameInputRef, walletQuoteName,
      walletQuoteValidDateParameterName, walletQuoteValidDate,
      walletQuoteDependentProductSectionIdInputRefs, walletQuoteDependentProductSectionContentTypeInputRefs, walletQuoteDependentProductSectionProductSectionIdRefs,
      walletQuoteDependentProductSubsectionIdRefs, walletQuoteDependentProductSubsectionProductSubsectionIdRefs,
      walletQuoteDependentProductSectionContentIdInputRefs,
      walletQuoteDependentProductSectionContentContentTypeInputRefs,
      walletQuoteDependentProductSectionContentContentIdInputRefs,
      walletQuoteDependentProductSectionContentTableStyleRefs,
      walletQuoteDependentProductSectionContentKeys, walletQuoteDependentProductSectionContents,
      productBaseSectionRefs,
      productContactInfoRefs,
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* General form */}
        <div className="row">
          <div className="form-group">
            <div className="field col-sm-6">
              <label className="form-label" htmlFor="wallet_quote[name]">
                Project
              </label>
              <input id="wallet_quote_name"
                     className="form-control"
                     name="wallet_quote[name]"
                     type="text"
                     ref={walletQuoteNameInputRef}
                     value={walletQuoteName}
                     onChange={ (event) => setWalletQuoteName(event.target.value) }/>
            </div>

            <div className="field col-sm-3">
              <label className="form-label" htmlFor="wallet_quote[valid_date]">
                Valid date
              </label>
              <div className='span12'>
                <DatePicker selected={walletQuoteValidDate} onChange={newDate => setWalletQuoteValidDate(newDate)} />
              </div>
            </div>
          </div>
        </div>

        {
          dependent_product_sections.length > 0 &&
          <div id="sections" className="grid-title">
            <h3>Sections:</h3>

            {
              dependent_product_sections.map((section, section_index) => {
                walletQuoteDependentProductSectionIdInputRefs.push(useRef());
                walletQuoteDependentProductSectionContentTypeInputRefs.push(useRef());
                walletQuoteDependentProductSectionProductSectionIdRefs.push(useRef());

                const sectionId = `wallet_quote_dependent_product_sections_attributes_${section_index}`;
                const sectionName = `wallet_quote[dependent_product_sections_attributes][${section_index}]`;
                return (
                  <>
                    <input
                      type="hidden"
                      id={`${sectionId}_id`}
                      name={`${sectionName}[id]`}
                      ref={walletQuoteDependentProductSectionIdInputRefs[section_index]}
                      value={section.id}
                    />
                    <input
                      type="hidden"
                      id={`${sectionId}_content_type`}
                      name={`${sectionName}[content_type]`}
                      ref={walletQuoteDependentProductSectionContentTypeInputRefs[section_index]}
                      value={section.content_type}
                    />
                    <input
                      type="hidden"
                      id={`${sectionId}_product_section_id`}
                      name={`${sectionName}[product_section_id]`}
                      ref={walletQuoteDependentProductSectionProductSectionIdRefs[section_index]}
                      value={section.product_section_id}
                    />
                    {
                      section
                        .dependent_product_subsections
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((subsection, subsection_index) => {

                        if (subsection_index === 0) {
                          walletQuoteDependentProductSubsectionIdRefs.push([useRef()]);
                          walletQuoteDependentProductSubsectionProductSubsectionIdRefs.push([useRef()])
                        } else {
                          walletQuoteDependentProductSubsectionIdRefs[section_index].push(useRef());
                          walletQuoteDependentProductSubsectionProductSubsectionIdRefs[section_index].push(useRef());
                        }

                        const subsectionId = `${sectionId}_dependent_product_subsections_attributes_${subsection_index}`;
                        const subsectionName = `${sectionName}[dependent_product_subsections_attributes][${subsection_index}]`;

                        return (
                          <>
                            <input
                              type="hidden"
                              id={`${subsectionId}_id`}
                              name={`${subsectionName}[id]`}
                              ref={walletQuoteDependentProductSubsectionIdRefs[section_index][subsection_index]}
                              value={subsection.id}
                            />
                            <input
                              type="hidden"
                              id={`${subsectionId}_product_subsection_id`}
                              name={`${subsectionName}[product_subsection_id]`}
                              ref={walletQuoteDependentProductSubsectionProductSubsectionIdRefs[section_index][subsection_index]}
                              value={subsection.product_subsection_id}
                            />

                            <h4>
                              {
                                section.name ? `${section.name} -> ${subsection.name}` : subsection.name
                              }
                            </h4>

                            {
                              (subsection.content_type === 'usual' || subsection.content_type === 'medium') &&
                                <>
                                  {
                                    subsection
                                      .dependent_product_section_contents
                                      .sort((a, b) => a.sequence - b.sequence)
                                      .map((content, content_index) => {

                                        const contentId = `${subsectionId}_dependent_product_section_contents_attributes_${content_index}`;
                                        const contentName = `${subsectionName}[dependent_product_section_contents_attributes][${content_index}]`;

                                        const [
                                          walletQuoteSectionContentKey,
                                          productSectionContentDisclaimer,
                                          walletQuoteSectionContent,
                                          setWalletQuoteSectionContent,
                                        ] = getFormData(content, contentName);

                                        if (subsection_index === 0 && content_index === 0) {
                                          walletQuoteDependentProductSectionContentIdInputRefs.push([[useRef()]]);
                                          walletQuoteDependentProductSectionContentContentTypeInputRefs.push([[useRef()]]);
                                          walletQuoteDependentProductSectionContentContentIdInputRefs.push([[useRef()]]);
                                          walletQuoteDependentProductSectionContentTableStyleRefs.push([[useRef()]]);

                                          walletQuoteDependentProductSectionContentKeys.push([[walletQuoteSectionContentKey]]);
                                          walletQuoteDependentProductSectionContents.push([[walletQuoteSectionContent]]);
                                          setWalletQuoteDependentProductSectionContents.push([[setWalletQuoteSectionContent]]);
                                        } else if (content_index === 0) {
                                          walletQuoteDependentProductSectionContentIdInputRefs[section_index].push([useRef()]);
                                          walletQuoteDependentProductSectionContentContentTypeInputRefs[section_index].push([useRef()]);
                                          walletQuoteDependentProductSectionContentContentIdInputRefs[section_index].push([useRef()]);
                                          walletQuoteDependentProductSectionContentTableStyleRefs[section_index].push([useRef()]);

                                          walletQuoteDependentProductSectionContentKeys[section_index].push([walletQuoteSectionContentKey]);
                                          walletQuoteDependentProductSectionContents[section_index].push([walletQuoteSectionContent]);
                                          setWalletQuoteDependentProductSectionContents[section_index].push([setWalletQuoteSectionContent]);
                                        } else {
                                          walletQuoteDependentProductSectionContentIdInputRefs[section_index][subsection_index].push(useRef());
                                          walletQuoteDependentProductSectionContentContentTypeInputRefs[section_index][subsection_index].push(useRef());
                                          walletQuoteDependentProductSectionContentContentIdInputRefs[section_index][subsection_index].push(useRef());
                                          walletQuoteDependentProductSectionContentTableStyleRefs[section_index][subsection_index].push(useRef());

                                          walletQuoteDependentProductSectionContentKeys[section_index][subsection_index].push(walletQuoteSectionContentKey);
                                          walletQuoteDependentProductSectionContents[section_index][subsection_index].push(walletQuoteSectionContent);
                                          setWalletQuoteDependentProductSectionContents[section_index][subsection_index].push(setWalletQuoteSectionContent);
                                        }

                                        return (
                                          <>
                                            <input
                                              type="hidden"
                                              id={`${contentId}_id`}
                                              name={`${contentName}[id]`}
                                              ref={walletQuoteDependentProductSectionContentIdInputRefs[section_index][subsection_index][content_index]}
                                              value={content.id}
                                            />
                                            <input
                                              type="hidden"
                                              id={`${contentId}_content_type`}
                                              name={`${contentName}[content_type]`}
                                              ref={walletQuoteDependentProductSectionContentContentTypeInputRefs[section_index][subsection_index][content_index]}
                                              value={content.content_type}/>
                                            <input
                                              type="hidden"
                                              id={`${contentId}_product_section_content_id`}
                                              name={`${contentName}[product_section_content_id]`}
                                              ref={walletQuoteDependentProductSectionContentContentIdInputRefs[section_index][subsection_index][content_index]}
                                              value={content.product_section_content_id}/>

                                            <div className="row">
                                              <div className="field col-sm-12">
                                                <div className="span12">
                                                  <i>{productSectionContentDisclaimer}</i>
                                                </div>
                                              </div>
                                            </div>

                                            {
                                              content.content_type === "gantt_chart" &&
                                                <GanttEditor
                                                  content={walletQuoteDependentProductSectionContents[section_index][subsection_index][content_index]}
                                                  setContent={setWalletQuoteDependentProductSectionContents[section_index][subsection_index][content_index]} />
                                            }
                                            {
                                              content.content_type === "html_table" &&
                                              <>
                                                <input
                                                  type="hidden"
                                                  id={`${contentId}_table_style`}
                                                  name={`${contentName}[table_style]`}
                                                  ref={walletQuoteDependentProductSectionContentTableStyleRefs[section_index][subsection_index][content_index]}
                                                  value={content.table_style}/>
                                                <TableEditor
                                                  content={walletQuoteDependentProductSectionContents[section_index][subsection_index][content_index]}
                                                  setContent={setWalletQuoteDependentProductSectionContents[section_index][subsection_index][content_index]} />
                                              </>
                                            }
                                            {
                                              (content.content_type === "text" || content.content_type === "pretty_list") &&
                                              <ReactSummernote
                                                options={{
                                                  height: 350,
                                                  dialogsInBody: true,
                                                  toolbar: [
                                                    ["style", []],
                                                    ["font", ["bold", "italic", "underline"]],
                                                    ["para", ["ul", "ol"]],
                                                    ["insert", []],
                                                    ["view", ["codeview"]]
                                                  ]
                                                }}
                                                onChange={ (content) => setWalletQuoteDependentProductSectionContents[section_index][subsection_index][content_index](cleanHTML(content)) }>
                                                {htmlParser(walletQuoteDependentProductSectionContents[section_index][subsection_index][content_index])}
                                              </ReactSummernote>
                                            }
                                          </>
                                        );
                                      })
                                  }
                                </>
                            }

                            {
                              (subsection.content_type === 'pretty_list') &&
                              <div className="well">
                                <ProductSubsectionPrettyList
                                  baseContents={subsection.base_contents.sort((a, b) => a.sequence - b.sequence)}
                                  subsectionId={subsectionId}
                                  subsectionName={subsectionName}
                                  productBaseSectionRefs={productBaseSectionRefs}
                                  columnCount={2}
                                />
                              </div>
                            }

                            {
                              (subsection.content_type === 'contact_info') &&
                              <div className="well">
                                <ProductSubsectionContactInfo
                                  contactInfo={subsection.contact_info}
                                  subsectionId={subsectionId}
                                  subsectionName={subsectionName}
                                  productContactInfoRefs={productContactInfoRefs}
                                  fields={[
                                    { name: 'Phone', property: 'phone' },
                                    { name: 'Email', property: 'email' },
                                  ]}
                                />
                              </div>
                            }
                          </>
                        );
                      })
                    }
                  </>
                );
              })
            }
          </div>
        }

        {/* TODO: add block if submitted */}
        <div className="actions">
          <button className="btn btn-primary" name="button" type="submit">Save</button>
        </div>
      </form>
    </>
  );
}
